<template>
  <div>
    <!-- myDiv 计算文本宽度高度 -->
    <div id="myDiv"></div>
    <!-- myCanvas 也来计算文本宽度高度 -->
    <canvas id="myCanvas" style="display: none"></canvas>

    <div id="label_container2" style="height: calc(70vh - 150px); width: 100%">
      <div id="container" style="height: 100%; width: 100%"></div>
    </div>

    <el-popover ref="popover3" placement="bottom-end">
      <el-cascader-panel
        id="tara_cascader_panel"
        class="tara_jilian tara_jilian——long"
        :options="tara_data"
      ></el-cascader-panel>
      <el-button slot="reference" type="primary" style="margin-bottom: 20px">{{
        $t("tara.t15")
      }}</el-button>
    </el-popover>

    <!-- 删除节点 -->
    <el-dialog
      :title="$t('tara.t17')"
      :visible.sync="del_dialog"
      width="25%"
      height="8vh"
      style="text-align: left"
      append-to-body
    >
      <i
        style="font-size: 20px; color: rgb(255, 195, 0)"
        class="iconfont icon-jingshi-tianchong"
      ></i>
      <span>&nbsp;{{ $t("tara.t18") }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="confirm_del">
          {{ $t("btn.deleteBtn") }}
        </el-button>
        <el-button @click="del_dialog = false">
          {{ $t("btn.cancelBtn") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { Graph } from "@antv/x6";
import Hierarchy from "@antv/hierarchy";
import {
  get_CustomizeOptions,
  put_CustomizeOptions,
} from "@/network/hara/index.js";

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    antv_type: {
      type: String,
    },
  },
  data() {
    return {
      graph: "",
      node_width: 250,
      node_padding_width: 20,
      btn_width: 20,
      y: 0,
      width: 300,
      height: 300,
      graph_tree_data: {},
      tara_node_id: "",
      del_dialog: false,
      add_node_id: null,
      is_edit: false,
      first: true,
      tara_data: [],
    };
  },
  computed: {
    p_id() {
      return this.get_pid();
    },
  },
  watch: {},
  created() {
    get_CustomizeOptions(this.get_pid()).then((res) => {
      console.log(res);
      if (Object.keys(res).length === 0) {
        // let data = {
        //   id: "root",
        //   topic: "hara" + this.$t("hara.tip"),
        //   parentId: "root",
        //   layer: 1,
        //   children: [],
        // };
        let data = {
          id: "root",
          layer: 1,
          topic: "hara",
          children: [
            {
              id: "hara1712023700448luhqphpc",
              layer: 2,
              topic: "1.1 致命故障",
              children: [
                {
                  id: "hara1712023744371luhqqflf",
                  layer: 3,
                  topic: "制动系故障-行驶中严重滑油、制动失效",
                  children: [],
                  parentId: "hara1712023700448luhqphpc",
                },
                {
                  id: "hara1712023746275luhqqh2b",
                  layer: 3,
                  topic: "变速器失效-山于齿轮报废造成变速器损坏",
                  children: [],
                  parentId: "hara1712023700448luhqphpc",
                },
                {
                  id: "hara1712023747355luhqqhwb",
                  layer: 3,
                  topic: "方向盘-行驶中固定螺母脱落\n",
                  children: [],
                  parentId: "hara1712023700448luhqphpc",
                },
              ],
              parentId: "root",
            },
            {
              id: "hara1712023702509luhqpjal",
              layer: 2,
              topic: "1.2 严重故障",
              children: [
                {
                  id: "hara1712023750301luhqqk65",
                  layer: 3,
                  topic: "转向系-转向明显沉重,正常保养不能排除。",
                  children: [],
                  parentId: "hara1712023702509luhqpjal",
                },
              ],
              parentId: "root",
            },
          ],
          parentId: "root",
        };
        put_CustomizeOptions(this.get_pid(), data);
        this.graph_tree_data = data;
        this.generate_data(this.graph_tree_data);
        this.init_nodes_edges(this.graph_tree_data);
      } else {
        this.graph_tree_data = res.options;
        this.generate_data(this.graph_tree_data);
        this.init_nodes_edges(this.graph_tree_data);
      }
    });
  },
  mounted() {
    this.init();
  },
  methods: {
    generate_data(res) {
      function addLabelAndValue(array) {
        array.forEach((item, index) => {
          item.label = item.topic;
          item.value = item.topic + index;

          if (Array.isArray(item.children)) {
            addLabelAndValue(item.children);
            if (item.children.length === 0) {
              delete item.children;
            }
          }
        });

        return array;
      }
      let arr = res.children || [];

      arr = JSON.parse(JSON.stringify(arr));

      this.tara_data = addLabelAndValue(arr);
    },
    generate_id() {
      return "hara" + Date.now() + Date.now().toString(36);
    },
    clear() {
      function updateNodeDataById(tree, nodeId, newData) {
        function updateNodeRecursive(node) {
          if (node.id === nodeId) {
            node.topic = newData;
            return;
          }

          if (node.children) {
            for (let i = 0; i < node.children.length; i++) {
              updateNodeRecursive(node.children[i]);
            }
          }
        }

        updateNodeRecursive(tree);
        return tree;
      }

      let textareaElement = document.querySelector("#edit_textarea");
      if (textareaElement) {
        this.graph_tree_data = updateNodeDataById(
          this.graph_tree_data,
          this.tara_node_id,
          textareaElement.value.length == 0 ? "new node" : textareaElement.value
        );
        let data = {
          options: this.graph_tree_data,
        };
        put_CustomizeOptions(this.get_pid(), this.graph_tree_data).then(() => {
          this.graph.clearCells();
          this.generate_data(this.graph_tree_data);
          this.init_nodes_edges(this.graph_tree_data);
          this.is_edit = false;
        });
      }
    },
    add_node(id) {
      let data = {
        id: this.generate_id(),
        layer: 0,
        parentId: "",
        children: [],
        topic: "new node",
      };

      function traverse(node) {
        if (node.id === id) {
          data.layer = parseInt(node.layer) + 1;
          data.parentId = node.id;
          node.children.push(data);
          return true;
        }
        if (node.children && node.children.length > 0) {
          for (let child of node.children) {
            if (traverse(child)) {
              return true;
            }
          }
        }
        return false;
      }

      if (traverse(this.graph_tree_data)) {
        let add_data = {
          optionsType: this.antv_type,
          options: this.graph_tree_data,
        };
        put_CustomizeOptions(this.get_pid(), this.graph_tree_data).then(() => {
          this.graph.clearCells();
          this.generate_data(this.graph_tree_data);
          this.add_node_id = data.id;
          this.init_nodes_edges(this.graph_tree_data);
        });
      }
    },

    del_node(id) {
      this.del_dialog = true;
    },

    confirm_del() {
      function deleteNodeById(tree, nodeIdToDelete) {
        if (tree === null || typeof tree !== "object") {
          return;
        }

        function deleteNodeRecursive(node) {
          if (node.id === nodeIdToDelete) {
            return null;
          }

          if (node.children) {
            node.children = node.children
              .map((child) => deleteNodeRecursive(child))
              .filter((child) => child !== null);
          }

          return node;
        }
        return deleteNodeRecursive(tree);
      }
      const updatedTree = deleteNodeById(
        this.graph_tree_data,
        this.tara_node_id
      );
      let data = {
        optionsType: this.antv_type,
        options: updatedTree,
      };
      put_CustomizeOptions(this.get_pid(), updatedTree).then(() => {
        this.graph.clearCells();
        this.graph_tree_data = updatedTree;
        this.generate_data(this.graph_tree_data);
        this.init_nodes_edges(this.graph_tree_data);
        this.del_dialog = false;
      });
    },

    modify_node_data(obj) {
      if (obj.children && Array.isArray(obj.children)) {
        for (let i in obj.children) {
          obj.children[i] = this.modify_node_data(obj.children[i]);
        }
      }
      if (parseInt(obj.layer) == 4) {
        return this.create_leaf_node(obj);
      } else if (parseInt(obj.layer) == 1) {
        return this.create_root_node(obj);
      } else {
        return this.create_node(obj);
      }
    },

    init_nodes_edges(data) {
      let use_data = JSON.parse(JSON.stringify(data));
      use_data = this.modify_node_data(use_data);
      let result = Hierarchy.mindmap(use_data, {
        direction: "H",
        getHeight(d) {
          return d.height;
        },
        getHGap(d) {
          return 20;
        },
        getVGap() {
          return 10;
        },
        getSide: () => {
          return "right";
        },
      });

      let l = [];
      l.push(result);
      // 循环添加节点
      while (l.length > 0) {
        let n = l.shift();
        let d = n.data;
        d.x = n.x + 10;
        d.y = n.y + 10;

        this.graph.addNode(d); // 添加节点
        if (n.data.parentId) {
          this.graph.addEdge(this.create_edge(n.data.id, n.data.parentId)); // 添加边
        }
        l = [...l, ...n.children];
      }

      if (this.first) {
        this.first = false;
        this.graph.centerContent(); // 视图居中

        // this.graph.zoomToFit({ maxScale: 1 }); // 视图放缩
      }

      if (this.add_node_id) {
        this.tara_node_id = this.add_node_id;
        if (document.getElementById("del_btn@" + this.add_node_id)) {
          document.getElementById("del_btn@" + this.add_node_id).style.display =
            "none";
        }
        if (document.getElementById("add_btn@" + this.add_node_id)) {
          document.getElementById("add_btn@" + this.add_node_id).style.display =
            "none";
        }
        this.is_edit = true;

        let divElement = document.getElementById("node@" + this.add_node_id);
        const h = divElement.style.height;
        let textareaElement = document.createElement("textarea");
        textareaElement.id = "edit_textarea";
        textareaElement.textContent = divElement.textContent;
        divElement.textContent = "";
        textareaElement.style.cssText = divElement.style.cssText;
        divElement.parentNode.style.width = "300px";

        divElement.style.height = "auto";
        divElement.style.border = "none";
        divElement.style.backgroundColor = "#f1c40f";
        textareaElement.style.backgroundColor = "white";
        textareaElement.style.color = "black";
        textareaElement.style.cursor = "auto";
        textareaElement.style.borderColor = "black";
        divElement.style.padding = "7px";
        divElement.appendChild(textareaElement);

        this.graph.centerCell(this.graph.getCellById(this.add_node_id));

        textareaElement.addEventListener("input", function () {
          console.log(this.clientHeight, this.clientHeight < 150);
          if (this.clientHeight < 150) {
            if (this.scrollHeight > this.clientHeight) {
              this.style.height = this.scrollHeight + 5 + "px";
            }
          }
        });
        textareaElement.dispatchEvent(new Event("input", { bubbles: true }));
        this.graph.getCellById(this.add_node_id).toFront();
        textareaElement.focus();
        let textLength = textareaElement.value.length;
        textareaElement.selectionStart = textLength;
        textareaElement.selectionEnd = textLength;
        this.add_node_id = null;
      }
    },

    init() {
      this.graph = new Graph({
        // 具体参数见官方文档 https://x6.antv.vision/zh/docs/api/graph/graph
        container: document.getElementById("container"),
        scroller: true,
        interacting: false,
        autoResize: true,
        mousewheel: {
          enabled: true,
          modifiers: ["ctrl", "meta"],
        },
        // panning: {
        //   enabled: true,
        //   modifiers: ["ctrl", "meta"],
        // },
        // panning: true,
      });

      // 点击节点事件
      this.graph.on("cell:click", ({ e, x, y, node, view }) => {
        let target = e.target.id.split("@");
        let obj_id = view.cell.id;
        if (target[0] == "add_btn") {
          this.add_node(obj_id);
        } else if (target[0] == "del_btn") {
          this.tara_node_id = obj_id;
          this.del_node(obj_id);
        } else {
          if (target[0] != "edit_textarea") {
            this.clear();
          }
        }
      });

      // 点击blank事件
      this.graph.on("blank:click", ({ e, x, y, node, view }) => {
        this.clear();
      });

      // hover节点事件
      this.graph.on("node:mouseenter", ({ e, x, y, node, view }) => {
        if (this.is_edit) {
          return;
        }
        let obj_id = view.cell.id;

        if (obj_id.slice(0, -1) == "label") {
          return false;
        }
        if (document.getElementById("del_btn@" + obj_id)) {
          document.getElementById("del_btn@" + obj_id).style.display = "block";
        }

        if (document.getElementById("add_btn@" + obj_id)) {
          document.getElementById("add_btn@" + obj_id).style.display = "block";
        }
      });

      // hover节点事件
      this.graph.on("cell:mouseleave", ({ e, x, y, node, view }) => {
        let obj_id = view.cell.id;
        if (document.getElementById("del_btn@" + obj_id)) {
          document.getElementById("del_btn@" + obj_id).style.display = "none";
        }
        if (document.getElementById("add_btn@" + obj_id)) {
          document.getElementById("add_btn@" + obj_id).style.display = "none";
        }
      });

      // dblclick节点事件
      this.graph.on("node:dblclick", ({ e, x, y, node, view }) => {
        if (this.is_edit) {
          return;
        }
        let obj_id = view.cell.id;
        if (obj_id == "root") {
          return;
        }
        this.tara_node_id = obj_id;
        if (document.getElementById("del_btn@" + obj_id)) {
          document.getElementById("del_btn@" + obj_id).style.display = "none";
        }
        if (document.getElementById("add_btn@" + obj_id)) {
          document.getElementById("add_btn@" + obj_id).style.display = "none";
        }
        this.is_edit = true;

        let divElement = e.target;
        const h =
          parseInt(divElement.style.height) > 150
            ? "150px"
            : divElement.style.height;
        let textareaElement = document.createElement("textarea");
        textareaElement.id = "edit_textarea";
        textareaElement.textContent = divElement.textContent;
        divElement.textContent = "";
        textareaElement.style.cssText = divElement.style.cssText;
        divElement.parentNode.style.width = "300px";

        divElement.style.height = "auto";
        divElement.style.border = "none";
        divElement.style.backgroundColor = "#f1c40f";
        textareaElement.style.backgroundColor = "white";
        textareaElement.style.color = "black";
        textareaElement.style.cursor = "auto";
        textareaElement.style.borderColor = "black";
        divElement.style.padding = "7px";
        textareaElement.style.height = h;
        divElement.appendChild(textareaElement);
        // divElement.parentNode.removeChild(divElement);

        // this.graph.centerCell(node);

        let minHeight = "50px";
        let maxHeight = h;

        textareaElement.addEventListener("input", function () {
          if (this.clientHeight < 145) {
            if (this.scrollHeight > this.clientHeight) {
              this.style.height = this.scrollHeight + 5 + "px";
            }
          }
        });

        textareaElement.dispatchEvent(new Event("input", { bubbles: true }));
        node.toFront();
        textareaElement.focus();
        let textLength = textareaElement.value.length;
        textareaElement.selectionStart = textLength;
        textareaElement.selectionEnd = textLength;
      });
    },
    calculate_node_width_height(text) {
      // 根据文本，计算每个节点的宽
      const canvas = document.getElementById("myCanvas");
      const ctx = canvas.getContext("2d");
      ctx.font = "18px Arial";
      ctx.fillText(text, 0, 0);
      const width = ctx.measureText(text).width;

      return width;
    },
    new_calculate_node_width_height(text) {
      // 根据文本，计算每个节点的高
      const div = document.getElementById("myDiv");
      div.innerText = text;
      return div.clientHeight;
    },
    create_edge(source_node, target_node) {
      let data = {
        source: source_node, // 起始节点 id
        target: target_node, // 目标节点 id
        markup: [
          {
            tagName: "path",
            selector: "stroke",
          },
        ],
        attrs: {
          stroke: {
            fill: "none",
            stroke: "#8f8f8f",
            connection: true,
            strokeWidth: 2,
            strokeLinecap: "round",
          },
        },

        router: {
          name: "er",
          args: {
            direction: "R",
            offser: "center",
          },
        },
        connector: "rounded",
      };
      return data;
    },
    create_node(tree_node) {
      let that = this;

      let width = this.calculate_node_width_height(tree_node.topic);

      let node_height =
        10 + this.new_calculate_node_width_height(tree_node.topic);
      let node_width =
        (width > this.node_width ? this.node_width : width) +
        this.node_padding_width +
        2;
      let data = {
        width: node_width,
        height: node_height,
        shape: "html",
        id: tree_node.id,
        layer: tree_node.layer,
        parentId: tree_node.parentId,
        data: tree_node.data ? tree_node.data : {},
        children: tree_node.children,

        html() {
          const con = document.createElement("div"); //
          const node = document.createElement("div"); // 节点
          const add_btn = document.createElement("div"); //
          const del_btn = document.createElement("div"); //
          const add_btn_i = document.createElement("div"); //
          const del_btn_i = document.createElement("div"); //

          con.style.display = "flex";
          con.style.alignItems = "center";

          node.id = "node@" + tree_node.id;
          node.style.minWidth = node_width + "px";
          node.style.height = node_height + "px";
          node.style.background = "#428bca";
          node.style.display = "flex";
          node.style.justifyContent = "center";
          node.style.alignItems = "center";
          node.style.border = "1px solid #357ebd";
          node.style.borderRadius = "5px";
          node.style.padding = "5px 10px";
          node.style.wordBreak = "break-all";
          node.style.font = "18px Arial";
          node.style.color = "white";
          node.style.lineHeight = "25px";
          node.style.cursor = "pointer";
          node.innerText = tree_node.topic;

          add_btn.style.minWidth = that.btn_width + "px";
          add_btn.style.cursor = "pointer";
          add_btn.id = "add_btn@" + tree_node.id;
          add_btn.style.height = node_height + "px";
          add_btn.style.color = "white";
          add_btn.style.font = "26px Arial";
          // btn.style.visibility = "hidden";
          add_btn.style.display = "none";
          add_btn.style.backgroundColor = "white";

          add_btn_i.innerText = "+";
          add_btn_i.style.color = "white";
          add_btn_i.style.border = "1px solid rgb(66,139,202)";
          add_btn_i.style.width = that.btn_width + "px";
          add_btn_i.style.height = that.btn_width + "px";
          add_btn_i.style.borderRadius = "50%";
          add_btn_i.style.display = "flex";
          add_btn_i.style.alignItems = "center";
          add_btn_i.style.justifyContent = "center";
          add_btn_i.style.backgroundColor = "rgb(66,139,202)";
          add_btn_i.id = "add_btn@";

          del_btn.style.minWidth = that.btn_width + "px";
          del_btn.style.cursor = "pointer";
          del_btn.id = "del_btn@" + tree_node.id;
          del_btn.style.height = node_height + "px";
          del_btn.style.color = "white";
          del_btn.style.font = "26px Arial";
          // del_btn.style.visibility = "hidden";
          del_btn.style.display = "none";
          del_btn.style.marginLeft = "-20px";
          del_btn.style.backgroundColor = "white";

          del_btn_i.innerText = "-";
          del_btn_i.style.color = "white";
          del_btn_i.style.border = "1px solid #F56C6C";
          del_btn_i.style.width = that.btn_width + "px";
          del_btn_i.style.height = that.btn_width + "px";
          del_btn_i.style.borderRadius = "50%";
          del_btn_i.style.display = "flex";
          del_btn_i.style.alignItems = "center";
          del_btn_i.style.justifyContent = "center";
          del_btn_i.style.backgroundColor = "#F56C6C";
          del_btn_i.id = "del_btn@";

          add_btn.appendChild(add_btn_i);
          del_btn.appendChild(del_btn_i);
          con.appendChild(del_btn);
          con.appendChild(node);
          con.appendChild(add_btn);
          return con;
        },
      };
      return data;
    },
    create_leaf_node(tree_node) {
      let that = this;

      let width = this.calculate_node_width_height(tree_node.topic);

      let node_height =
        10 + this.new_calculate_node_width_height(tree_node.topic);
      let node_width =
        (width > this.node_width ? this.node_width : width) +
        this.node_padding_width +
        2;
      let data = {
        width: node_width,
        height: node_height,
        shape: "html",
        id: tree_node.id,
        layer: tree_node.layer,
        parentId: tree_node.parentId,
        data: tree_node.data ? tree_node.data : {},
        children: tree_node.children,

        html() {
          const con = document.createElement("div"); //
          const node = document.createElement("div"); // 节点
          const del_btn = document.createElement("div"); //
          const del_btn_i = document.createElement("div"); //

          con.style.display = "flex";
          con.style.alignItems = "center";

          node.id = "node@" + tree_node.id;
          node.style.minWidth = node_width + "px";
          node.style.height = node_height + "px";
          node.style.background = "#428bca";
          node.style.display = "flex";
          node.style.justifyContent = "center";
          node.style.alignItems = "center";
          node.style.border = "1px solid #357ebd";
          node.style.borderRadius = "5px";
          node.style.padding = "5px 10px";
          node.style.wordBreak = "break-all";
          node.style.font = "18px Arial";
          node.style.color = "white";
          node.style.lineHeight = "25px";
          node.style.cursor = "pointer";
          node.innerText = tree_node.topic;

          del_btn.style.minWidth = that.btn_width + "px";
          del_btn.style.cursor = "pointer";
          del_btn.id = "del_btn@" + tree_node.id;
          del_btn.style.height = node_height + "px";
          del_btn.style.color = "white";
          del_btn.style.font = "26px Arial";
          // del_btn.style.visibility = "hidden";
          del_btn.style.display = "none";
          del_btn.style.marginLeft = "-20px";
          del_btn.style.backgroundColor = "white";

          del_btn_i.innerText = "-";
          del_btn_i.style.color = "white";
          del_btn_i.style.border = "1px solid #F56C6C";
          del_btn_i.style.width = that.btn_width + "px";
          del_btn_i.style.height = that.btn_width + "px";
          del_btn_i.style.borderRadius = "50%";
          del_btn_i.style.display = "flex";
          del_btn_i.style.alignItems = "center";
          del_btn_i.style.justifyContent = "center";
          del_btn_i.style.backgroundColor = "#F56C6C";
          del_btn_i.id = "del_btn@";

          del_btn.appendChild(del_btn_i);
          con.appendChild(del_btn);
          con.appendChild(node);
          return con;
        },
      };
      return data;
    },
    create_root_node(tree_node) {
      let that = this;
      let width = this.calculate_node_width_height(tree_node.topic);

      let node_height =
        10 + this.new_calculate_node_width_height(tree_node.topic);
      let node_width =
        (width > this.node_width ? this.node_width : width) +
        this.node_padding_width +
        2;

      let data = {
        width: node_width,
        height: node_height,
        shape: "html",
        id: tree_node.id,
        layer: tree_node.layer,
        parentId: tree_node.parentId,
        data: tree_node.data ? tree_node.data : {},
        children: tree_node.children,

        html() {
          const con = document.createElement("div"); //
          const node = document.createElement("div"); // 节点
          const add_btn = document.createElement("div"); //
          const add_btn_i = document.createElement("div"); //

          con.style.display = "flex";
          con.style.alignItems = "center";

          node.id = "node@" + tree_node.id;
          node.style.minWidth = node_width + "px";
          node.style.height = node_height + "px";
          node.style.background = "#428bca";
          node.style.display = "flex";
          node.style.justifyContent = "center";
          node.style.alignItems = "center";
          node.style.border = "1px solid #357ebd";
          node.style.borderRadius = "5px";
          node.style.padding = "5px 10px";
          node.style.wordBreak = "break-all";
          node.style.font = "18px Arial";
          node.style.color = "white";
          node.style.lineHeight = "25px";
          node.innerText =
            tree_node.topic.length > 0 ? tree_node.topic : "new node";

          add_btn.style.minWidth = that.btn_width + "px";
          add_btn.style.cursor = "pointer";
          add_btn.id = "add_btn@" + tree_node.id;
          add_btn.style.height = node_height + "px";
          add_btn.style.color = "white";
          add_btn.style.font = "26px Arial";
          // btn.style.visibility = "hidden";
          add_btn.style.display = "none";
          add_btn.style.backgroundColor = "white";

          add_btn_i.innerText = "+";
          add_btn_i.style.color = "white";
          add_btn_i.style.border = "1px solid rgb(66,139,202)";
          add_btn_i.style.width = that.btn_width + "px";
          add_btn_i.style.height = that.btn_width + "px";
          add_btn_i.style.borderRadius = "50%";
          add_btn_i.style.display = "flex";
          add_btn_i.style.alignItems = "center";
          add_btn_i.style.justifyContent = "center";
          add_btn_i.style.backgroundColor = "rgb(66,139,202)";
          add_btn_i.id = "add_btn@";

          add_btn.appendChild(add_btn_i);
          con.appendChild(node);
          con.appendChild(add_btn);
          return con;
        },
      };
      return data;
    },
  },
};
</script>
<style lang="scss">
.tara_table_class_topic.tara_table_class_cell {
  vertical-align: top !important;
}
// .tara_table_class_topic_header {
//   vertical-align: center !important;
// }
// .tara_table_class_topic {
//   th:not(:first-child) {
//     vertical-align: top !important;
//   }
//   /* 样式设置 */
// }
.tara_jilian {
  border: none !important;
  .el-cascader-node__label {
    word-break: break-word !important;
  }
}
.tara_jilian——long {
  height: 200px;
  .el-cascader-menu__wrap {
    height: 200px;
  }
}
.el-table .cell {
  word-break: break-word !important;
}
.tara_table_class_cell {
  .cell {
    word-break: break-word !important;
  }
  word-break: break-word !important;
}
.tara_drawer {
  pointer-events: none !important;
  .el-drawer {
    pointer-events: auto !important;
  }
}
.tara_relation_select {
  .el-select-dropdown__item {
    display: flex;
  }
  .el-select-dropdown__item.selected::after {
    right: 10px !important;
  }
}
.tara_user_select {
  .el-select-dropdown__item {
    display: flex;
    align-items: center;
    .avatar {
      display: flex;
    }
    .select_item {
      margin-left: 14px;
    }
  }
}
#tara_cascader_panel {
  .el-cascader-node__label {
    white-space: normal;
    overflow: auto;
    text-overflow: ellipsis;
    word-break: break-all;
    text-align: start;
  }
  .el-cascader-node {
    width: 25vw;
    height: auto;
  }
}
</style>
<style lang="scss" scoped>
.bottom-resize {
  cursor: n-resize;
  width: 100%;
  height: 6px;
  font-size: 32px;
  user-select: none;
  position: absolute;
  z-index: 1;
}
.bottom-resize:hover {
  background-color: #0090f1;
}
.bottom-resize.acitve {
  background-color: #0090f1;
}
.tara_form1 {
  .radio {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    font-size: 16px;
  }
}
.tara_form2 {
  .select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    font-size: 16px;
    span {
      width: 100px;
      text-align: start;
    }
  }
}
.tara_form4 {
  .select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    font-size: 16px;
    span {
      width: 200px;
      text-align: start;
    }
  }
}
// .tara_table_class {
//   .cell {
//     word-break: break-word !important;
//   }
// }
.el-table .cell {
  word-wrap: break-word !important;
}
.tara_table_class_cell {
  word-wrap: break-word !important;
}
</style>
<style scoped>
.tara_label_buling :hover {
  color: blue;
}
::v-deep .el-table tbody tr:hover > td {
  background-color: transparent !important;
}
#myDiv {
  font: 18px Arial; /*no*/
  max-width: 250px; /*no*/
  line-height: 25px; /*no*/
  position: absolute;
  visibility: hidden;
  word-break: break-all;
}
.myDiv {
  font: 18px Arial; /*no*/
  max-width: 250px; /*no*/
  line-height: 25px; /*no*/
  word-break: break-all;
}
.fta_res {
  background-color: rgb(236, 236, 236);
  margin-right: 25px;
}
#container {
  width: 100%;
  /* height: 100%; */
}
.container {
  width: 100%;
}
</style>
